import React from 'react';
import Layout from '../components/layout';
import SEO from "../components/seo";
import Home from './homepage';

// import css
import '../styles/magnific-popup.css';
import '../styles/jquery.selectBox.css';
import '../styles/rangeslider.css';
import '../styles/animate.min.css';
import '../styles/jquery.mCustomScrollbar.css';
import '../styles/style.css';
import '../styles/skins/default.css';

const IndexPage = () => {
  const seo = { metaDesc: 'Inventiv.org - Supporting inventors world-wide' }

  return (
    <Layout activeMenu="home">
      <SEO title="Inventiv.org - Supporting inventors world-wide"  canonical='/' seo={seo} />
      <Home />
    </Layout>
  )
};

export default IndexPage;